import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Input from '../utils/Input';
import { setCityEdit, fetchCity, updateCity, deleteFilial } from '../../action/handbook';
import { isEqual } from 'lodash'
import cn from 'classnames';
import Loader from '../utils/Loader';
import { Link } from 'react-router-dom';
import ErrorContainer from '../utils/ErrorContainer';
import { YMaps, Map, SearchControl, Placemark } from 'react-yandex-maps';
import Switch from '../utils/Switch';

const styles = {
    loading: {
        minHeight: '500px',
        display: 'flex',
        justifyContent: 'center'
    }
}

function declOfNum(number, titles) {  
    const cases = [2, 0, 1, 1, 1, 2];  
    return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];  
}

class EditCity extends Component {
    componentWillUnmount() {
        this.props.setCityEdit(null);
    }

    componentDidMount() {
        this.props.fetchCity(this.props.match.params.id).then(city => {
            this.props.setCityEdit(city);
        });
    }
    
    renderSubHeader() {
        const points = this.props.edit.points.filter(item => !item.deleted).length;
        return (
            <div className="kt-subheader kt-grid__item">
                <div className="kt-container  kt-container--fluid ">
                <div className="kt-subheader__main">
                    <h3 className="kt-subheader__title">{this.props.edit.name ? this.props.edit.name : 'Введите название города *'}</h3>
                    <span className="kt-subheader__separator kt-subheader__separator--v"></span>
                    <div className="kt-subheader__group" id="kt_subheader_search">
                        <span className="kt-subheader__desc">
                            {points ? points + ' ' + declOfNum(points, ['филиал', 'филиала', 'филиалов']) : 'нет филиалов'}   
                        </span>
                    </div>
                </div>        
                <div className="kt-subheader__toolbar">
                    <button onClick={() => this.props.history.push('/handbook/cities')} className="btn btn-default btn-bold">
                        Назад
                    </button>
                </div>
            </div>
            </div>
        );
    }

    isValidCity() {
        const { name, article, coord, deliveryTime, deliveryWork, deliveryCost, freeDelivery, gift, minOrder, frontpad, idFilial, idFrontpadDelivery, idFrontpadPick, idStatusCancel, idStatusDone} = this.props.edit;
        if (frontpad && (!idFilial || !idFrontpadDelivery || !idFrontpadPick || !idStatusCancel || !idStatusDone)) return false;
        if (!name || !article || !coord || !deliveryTime || !deliveryWork || !deliveryCost || !freeDelivery || !gift || !minOrder) return false;
        if (this.props.edit.points.filter(item => item.deleted).length > 0) return true;

        if (isEqual(this.props.detail, this.props.edit)) return false;
        
        
        return true;
    }

    setCity = (field) => (e) => {
        const data = {...this.props.edit};
        data[field] = e.target.value;
        this.props.setCityEdit(data);
    }

    setHiddenClient = (value) => {        
        const data = {...this.props.edit};
        data.hiddenClient = value;
        this.props.setCityEdit(data);
    }

    onSubmit = (e) => {
        e.preventDefault();
        const edited = { ...this.props.edit };
        edited.points = edited.points.filter(item => !item.deleted);
        Promise.all(this.props.edit.points.filter(item => item.deleted).map(deleteFilial)).then(result => {
            this.props.updateCity(this.props.match.params.id, edited).then(result => {
                if (result) {
                    this.props.history.push('/handbook/cities');
                }
            });
        })
        
    }

    renderAdress(item) {
        return `${item.street}, ${item.house}${item.entrance ? `, подъезд ${item.entrance}` : ''}${item.room ? `, кв./оф. ${item.room}` : ''}`;
    }

    deleteFilial = (index, cancel) => (e) => {
        e.preventDefault();
        const edited = { ...this.props.edit };
        if (cancel) {
            delete edited.points[index].deleted;
        } else {
            edited.points[index].deleted = true;
        }
        
        this.props.setCityEdit(edited);
    }

    onResultShow = () => {
        if (this.map) {
            const index = this.map.getSelectedIndex();
            const result = this.map.getResultsArray();
            const coord = result[index].geometry.getCoordinates().join(',');
            this.setCoord(coord);
          }
    };

    setCoord = (value) => {
        const data = {...this.props.edit};
        data['coord'] = value;
        this.props.setCityEdit(data);
    }

    render() {
        const { loading, edit } = this.props;
        if (loading || !edit) {
            return (
                <div style={styles.loading}><Loader/></div>
            );
        }

        return (
            <Fragment>
                { this.renderSubHeader() }
                <div className="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <form onSubmit={this.onSubmit} className="kt-form">
                    <div className="kt-portlet">
                        <div className="kt-portlet__body">
                            <div className="row">
                                <div className="col-md-6">
                                    <Input
                                        label="Название города"
                                        required={true}
                                        onChange={this.setCity('name')}
                                        value={edit.name}
                                        placeholder="Город..."
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Input
                                        label="ID города на сайта"
                                        required={true}
                                        onChange={this.setCity('article')}
                                        value={edit.article}
                                        placeholder="ID города..."
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <Input
                                        label="Координаты"
                                        required={true}
                                        onChange={this.setCity('coord')}
                                        value={edit.coord}
                                        placeholder="Координаты..."
                                        disabled={true}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label className="col-10 col-form-label">Скрыт для клиентов</label>
                                    <div className="col-2">
                                        <Switch 
                                        onChange={((e) => this.setHiddenClient(e))}
                                        checked={edit.hiddenClient}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <YMaps
                                        style={{ padding: '25px ', marginTop: -30 }}
                                        query={{
                                            apikey: '71140458-f614-4101-ad58-f75c79f0f6fe'
                                        }}
                                        >
                                        <Map
                                            
                                            width="100%"
                                            defaultState={{ center: edit.coord.split(','), zoom: 10, controls: [] }}
                                        >
                                             <Placemark geometry={edit.coord.split(',')} />
                                            <SearchControl instanceRef={ref => this.map = ref} onResultShow={this.onResultShow} options={{ float: 'right' }}/>
                                        </Map>
                                    </YMaps>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="kt-portlet">
                        <div className="kt-portlet__body">
                            <div className="row" style={{ marginBottom: 20 }}>
                                <div className="col-md-6">
                                    <h5>
                                        Frontpad
                                    </h5>
                                </div>
                            </div>
                            <div className="row" >
                                <div className="col-md-12">
                                    <Input
                                        label="Frontpad токен"
                                        onChange={this.setCity('frontpad')}
                                        value={edit.frontpad}
                                        placeholder="Токен..."
                                    />
                                </div>
                            </div>
                            {
                                edit.frontpad && (
                                    <div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <Input
                                                    required={true}
                                                    label="ID основного филиала во Frontpad"
                                                    onChange={this.setCity('idFilial')}
                                                    value={edit.idFilial}
                                                    placeholder="ID филиала..."
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <Input
                                                    required={true}
                                                    label="ID доставки во Frontpad"
                                                    onChange={this.setCity('idFrontpadDelivery')}
                                                    value={edit.idFrontpadDelivery}
                                                    placeholder="ID доставки..."
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <Input
                                                    required={true}
                                                    label="ID самовывоза во Frontpad"
                                                    onChange={this.setCity('idFrontpadPick')}
                                                    value={edit.idFrontpadPick}
                                                    placeholder="ID самовывоза..."
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <Input
                                                    required={true}
                                                    label="ID статуса Выполнен"
                                                    onChange={this.setCity('idStatusDone')}
                                                    value={edit.idStatusDone}
                                                    placeholder="ID статуса..."
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <Input
                                                    required={true}
                                                    label="ID статуса Списан"
                                                    onChange={this.setCity('idStatusCancel')}
                                                    value={edit.idStatusCancel}
                                                    placeholder="ID статуса..."
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <Input
                                                    required={true}
                                                    label="ID статуса в обработке"
                                                    onChange={this.setCity('idStatusProgress')}
                                                    value={edit.idStatusProgress}
                                                    placeholder="ID в обработке..."
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-2">
                                                <Input
                                                    label="ID метки <Приложение>"
                                                    onChange={this.setCity('idTagApp')}
                                                    value={edit.idTagApp}
                                                    placeholder="ID метки..."
                                                />
                                            </div>
                                            <div className="col-md-2">
                                                <Input
                                                    label="ID метки <Сайт>"
                                                    onChange={this.setCity('idTagSite')}
                                                    value={edit.idTagSite}
                                                    placeholder="ID метки..."
                                                />
                                            </div>
                                            <div className="col-md-2">
                                                <Input
                                                    label="ID метки <Доставка>"
                                                    onChange={this.setCity('idTagDelivery')}
                                                    value={edit.idTagDelivery}
                                                    placeholder="ID метки..."
                                                />
                                            </div>
                                            <div className="col-md-2">
                                                <Input
                                                    label="ID метки <Самовывоз>"
                                                    onChange={this.setCity('idTagSam')}
                                                    value={edit.idTagSam}
                                                    placeholder="ID метки..."
                                                />
                                            </div>
                                            <div className="col-md-2">
                                                <Input
                                                    label="ID метки <Оплата терминал>"
                                                    onChange={this.setCity('idTagTerminal')}
                                                    value={edit.idTagTerminal}
                                                    placeholder="ID метки..."
                                                />
                                            </div>
                                            <div className="col-md-2">
                                                <Input
                                                    label="ID метки <Оплата наличными>"
                                                    onChange={this.setCity('idTagCash')}
                                                    value={edit.idTagCash}
                                                    placeholder="ID метки..."
                                                />
                                            </div>
                                            <div className="col-md-2">
                                                <Input
                                                    label="ID метки <Сдача с 1000>"
                                                    onChange={this.setCity('idTagCash1000')}
                                                    value={edit.idTagCash1000}
                                                    placeholder="ID метки..."
                                                />
                                            </div>
                                            <div className="col-md-2">
                                                <Input
                                                    label="ID метки <Сдача с 1500>"
                                                    onChange={this.setCity('idTagCash1500')}
                                                    value={edit.idTagCash1500}
                                                    placeholder="ID метки..."
                                                />
                                            </div>
                                            <div className="col-md-2">
                                                <Input
                                                    label="ID метки <Сдача с 2000>"
                                                    onChange={this.setCity('idTagCash2000')}
                                                    value={edit.idTagCash2000}
                                                    placeholder="ID метки..."
                                                />
                                            </div>
                                            <div className="col-md-2">
                                                <Input
                                                    label="ID метки <Сдача с 5000>"
                                                    onChange={this.setCity('idTagCash5000')}
                                                    value={edit.idTagCash5000}
                                                    placeholder="ID метки..."
                                                />
                                            </div>
                                            <div className="col-md-2">
                                                <Input
                                                    label="ID метки <Автораспределение>"
                                                    onChange={this.setCity('idTagAutoDistribution')}
                                                    value={edit.idTagAutoDistribution}
                                                    placeholder="ID метки..."
                                                />
                                            </div>
                                            <div className="col-md-2">
                                                <Input
                                                    label="ID метки <Онлайн-Оплата>"
                                                    onChange={this.setCity('idTagOnlinePay')}
                                                    value={edit.idTagOnlinePay}
                                                    placeholder="ID метки..."
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div className="kt-portlet">
                        <div className="kt-portlet__body">
                            <div className="row" style={{ marginBottom: 20 }}>
                                <div className="col-md-6">
                                    <h5>
                                        Yumapos
                                    </h5>
                                </div>
                            </div>
                            <div className="row" >
                                <div className="col-md-12">
                                    <Input
                                        label="Id филиала КЦ в Yumapos"
                                        onChange={this.setCity('yumaposPointId')}
                                        value={edit.yumaposPointId}
                                        placeholder="Id..."
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="kt-portlet">
                        <div className="kt-portlet__body">
                            <div className="row" style={{ marginBottom: 20 }}>
                                <div className="col-md-6">
                                    <h5>
                                        Филиалы
                                    </h5>
                                </div>
                            </div>
                            <div className="row" >
                                <div className="col-md-6">
                                    <div className="kt-wizard-v2__form">
                                    {
                                        edit.points.map((filial, index) => (
                                            <div key={index} className="form-group" style={{ marginBottom: '10px', paddingBottom: '10px', borderBottom: '1px solid #e5e5e5'}}>
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                    { this.renderAdress(filial) }
                                                    <div>
                                                    <button disabled={filial.deleted} style={{ marginLeft: '15px' }} onClick={this.deleteFilial(index)} className="btn btn-danger btn-sm">Удалить</button>
                                                    {
                                                        filial.deleted && (
                                                            <button style={{ marginLeft: 10 }} onClick={this.deleteFilial(index, 'cancel')} type="button" className="btn btn-secondary btn-sm">Отменить</button>
                                                        )
                                                    }
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }

                                    <Link style={{ marginTop: 20 }} to={{ pathname: '/handbook/filials/add', state: { city: edit.id, from: 'edit' } }} className="btn btn-primary">Добавить филиал</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="kt-portlet">
                        <div className="kt-portlet__body">

                            <div className="row" style={{ marginBottom: 20 }}>
                                <div className="col-md-6">
                                    <h5>
                                        Настройки по умолчаю для филиалов
                                    </h5>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <Input
                                        label="Время доставки"
                                        required={true}
                                        onChange={this.setCity('deliveryTime')}
                                        value={edit.deliveryTime}
                                        placeholder="Время доставки..."
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Input
                                        label="Время работы доставки"
                                        required={true}
                                        onChange={this.setCity('deliveryWork')}
                                        value={edit.deliveryWork}
                                        placeholder="Время работы..."
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <Input
                                        label="Стоимость доставки"
                                        required={true}
                                        onChange={this.setCity('deliveryCost')}
                                        value={edit.deliveryCost}
                                        placeholder="Стоимость доставки..."
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Input
                                        label="Бесплатная доставка от"
                                        required={true}
                                        onChange={this.setCity('freeDelivery')}
                                        value={edit.freeDelivery}
                                        placeholder="Бесплатная доставка от..."
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <Input
                                        label="Подарок от суммы"
                                        required={true}
                                        onChange={this.setCity('gift')}
                                        value={edit.gift}
                                        placeholder="Подарок от суммы..."
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Input
                                        label="Минимальная сумма для доставки"
                                        required={true}
                                        onChange={this.setCity('minOrder')}
                                        value={edit.minOrder}
                                        placeholder="Минимальная сумма..."
                                    />
                                </div>
                            </div>
                        </div>
                        <ErrorContainer field="editCity" style={{ margin: '0 20px 20px' }} hasCloseButton={true}/>
                        <div className="kt-portlet__foot">
                            <div className="kt-form__actions">
                                <button
                                    disabled={!this.isValidCity() || this.props.loading}
                                    type="submit"
                                    className={cn({ 'btn btn-brand  kt-spinner--right kt-spinner--sm kt-spinner--light': true, 'kt-spinner': this.props.editCityLoader })}>
                                    Сохранить
                                </button>
                            </div>
                        </div>
                    
                </div>
                </form>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    edit: state.handbook.city.edit,
    detail: state.handbook.city.detail,
    loading: state.loaders.city,
    editCityLoader: state.loaders.editCity
});

const mapDispatchToProps = {
    setCityEdit: (data) => setCityEdit(data),
    fetchCity: (id) => fetchCity(id),
    updateCity: (id, data) => updateCity(id, data)
}

export default connect(mapStateToProps, mapDispatchToProps)(EditCity);