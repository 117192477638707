import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { urls } from "../../constants/app";
import request from "../../action/utils";
import { v4 as uuidv4 } from 'uuid';

const SaveNewOrderButton = () => {
    const dispatch = useDispatch();
    const stateNewOrders= useSelector(state => state.neworders);

    const handleClickSave = () => {    
        dispatch({ type: 'SET_LOADER', field: 'loadingSaveOrder', value: true });
        const order = createNewOrder();
        return request({ method: 'post', url: urls.orders, data: order })
        .then(() => {
            dispatch({ type: 'CLEAR_NEW_ORDER' });
            alert('Заказ оформлен успешно');
        })
        .catch((er) => {        
            alert('Ошибка ' + er.status);
            console.log(er);
        })
        .finally(() => {
            dispatch({ type: 'SET_LOADER', field: 'loadingSaveOrder', value: false });
        })
    }
    
    const createNewOrder = () => {    
        let checkChangeCash = stateNewOrders.changeCash
        let checkComment = String(stateNewOrders.comment)
        if (stateNewOrders.changeCash && !['no', 'c1000', 'c1500', 'c2000', 'c5000'].includes(stateNewOrders.changeCash))
        {
            checkChangeCash = null
            checkComment = checkComment + String(stateNewOrders.changeCash ? ' | сдача с '+ stateNewOrders.changeCash.slice(1) : '')
        }
    
        const checkGoods = stateNewOrders.goods.map((item) => {
            if(!item.wokData)
                return {
                    id: item.id,
                    article: item.article,
                    isMod: item.isMod ? !item.isMod.default : false,
                    count: item.count,
                    isWok: false,
                    wokData: null,
                    price: item.price,
                    category: item.category.id,
                    categoryName: item.category.title,
                    data: {
                        title: item.name,
                        weight: item.weight,
                    },
                }
    
                const hash = (() => {
                    const check = {
                      [item.id]: 1,
                    };
                    if(item.wokData.souce)
                    Object.values(item.wokData.souce).forEach((item) => {
                      check[item.id] = 1;
                    });
                    if (item.wokData.meat) {
                    Object.values(item.wokData.meat).forEach((item) => {
                        check[item.id] = item.count;
                      });
                    }
                    if (item.wokData.topping) {
                    Object.values(item.wokData.topping).forEach((item) => {
                        check[item.id] = item.count;
                      });
                    }
                    const uuid = uuidv4(JSON.stringify(check));
                    return uuid;
                  });
    
            return {
                id: hash,
                isMod: false,
                count: item.count,
                isWok: true,
                wokData: {...item.wokData, id: hash}, 
            }
        }) 
    
        const newOrder = {
            deliveryMethod: stateNewOrders.deliveryMethod,
            point: stateNewOrders.point.id,
            city: stateNewOrders.point.city.id,        
            phone: String(stateNewOrders.phone),
            comment: checkComment,
            timeMethod: 'fast',
            deliveryPrice: stateNewOrders.deliveryPrice,
            deliveryArticle: stateNewOrders.deliveryArticle,
            deliveryTime: null,
            deliveryTimeComment: null,
            totalPrice: parseFloat(stateNewOrders.totalPrice),
            goodsPrice: parseFloat(stateNewOrders.goodPrice),
            salePrice: parseFloat(stateNewOrders.salePrice),
            unit: parseInt(stateNewOrders.unit) ? parseInt(stateNewOrders.unit) : null,
            goods: checkGoods,
            payment: stateNewOrders.payment,
            changeCash: checkChangeCash,
            newApi: true,
            isSite: true,
            isAggPrice: stateNewOrders.isAggPrice,
            bonusCount: 0,
            zone: null,
            user: null,
            userName: null,
            source: 'crm'
        }
    
        if(stateNewOrders.deliveryMethod === 'delivery')
        {
            newOrder.street = stateNewOrders.street;
            newOrder.house = stateNewOrders.house;
            newOrder.room = stateNewOrders.room;
            newOrder.entrance = stateNewOrders.entrance;
            newOrder.floor = stateNewOrders.floor;
        }
    
        return newOrder;
    }

    return (
        <button className="order_position_list_footer_button save" onClick={handleClickSave}>Сохранить</button>
    );
}

export default SaveNewOrderButton;


