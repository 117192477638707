import React, { Component } from 'react';
import Select from 'react-select';
import { withRouter  } from 'react-router-dom';
import moment from 'moment';
import { updateOrderFromTable } from '../../action/orders';
import { connect } from 'react-redux';
import swal from 'sweetalert';

class OrdersTable extends Component {      
    constructor(props) {
        super(props);
        this.state = {
          showDetails: Array(this.props.orders.length).fill(false)
        };
      }
      
    handleDoubleClick = (item) => () => {
        const url = this.props.isArchive ? '/archive' : '/orders';
        this.props.history.push(`${url}/${+item.id}`)
    }

    handleClickCopy = (text) => () => {
        navigator.clipboard.writeText(text)
    }

    toggleDetails(index) {
        this.setState(prevState => {
          const updatedShowDetails = [...prevState.showDetails]
          updatedShowDetails[index] = !updatedShowDetails[index]
          return { showDetails: updatedShowDetails }
        })
    }

    changePoint = (value, item) => {
        let data = {}

        if(!value)
            data = {
                point: null,
                zone: null
            };

        if(value)
            data = {
                city: this.props.filials.find((el) => el.id === value).city,
                point: this.props.filials.find((el) => el.id === value),
                zone: null
            };

        this.props.updateOrderFromTable(item.id, data, item.isArchive);   
    }

    changeStatus = (e, item) => {
        if(item.status !== e.target.value)
        {
            const data = {
                status: e.target.value
            };

            if (data.status === 'cancel') {
                return swal({
                    title: "Изменение статуса заказа",
                    text: "Вы действительно хотите отменить заказ?",
                    icon: "warning",
                    dangerMode: true,
                    buttons: ["Отмена", "Отменить заказ"],
                }).then((willDelete) => {
                    if (willDelete) {
                        this.props.updateOrderFromTable(item.id, data, item.isArchive);
                    }
                });
            }

            if (data.status === 'written-off') {
                return swal({
                    title: "Изменение статуса заказа",
                    text: "Вы действительно хотите списать заказ?",
                    icon: "warning",
                    dangerMode: true,
                    buttons: ["Отмена", "Списать заказ"],
                }).then((willDelete) => {
                    if (willDelete) {
                        this.props.updateOrder(this.props.order.id, data, this.props.isArchive);
                    }
                });
            }

            return this.props.updateOrderFromTable(item.id, data, item.isArchive);
            
        }      
    }

    renderWok = (good, index, isAggPrice) => {
        return (
            <>
            <tr key={index} style={{ minWidth: '310px', padding: '10px 5px'}}>
                <td>{good.wokData.main.title}</td>
                <td style={{ paddingLeft: '5px', width: '25px', textAlign: 'start', color: '#000'}}>{good.count}</td>
                <td style={{ paddingRight: '5px', width: '40px', textAlign: 'end', color: '#000'}}>{good.price}</td>                
                {!isAggPrice 
                ? 
                <td style={{ paddingRight: '5px', width: '40px', textAlign: 'end', color: '#000'}}>{good.wokData.main.price}</td> 
                :
                <td style={{ paddingRight: '5px', width: '40px', textAlign: 'end', color: '#000'}}>{good.wokData.main.oldPrice}</td>
                }              
            </tr>
            {good.wokData.meat && Object.values(good.wokData.meat).map((el) =>
                <tr key={el.id + index} style={{ minWidth: '310px', padding: '10px 5px'}}>
                    <td className="order-table-order-card__name">{'--' + el.data.title}</td>               
                    <td style={{ paddingLeft: '5px', width: '25px', textAlign: 'start', color: '#000'}}>{el.count}</td>
                    {!isAggPrice 
                    ? 
                    <td style={{ paddingRight: '5px', width: '40px', textAlign: 'end', color: '#000'}}>{el.data.price}</td>
                    :
                    <td style={{ paddingRight: '5px', width: '40px', textAlign: 'end', color: '#000'}}>{el.data.oldPrice}</td>
                    }
                </tr>
            )}
            {good.wokData.topping && Object.values(good.wokData.topping).map((el) =>
                <tr key={el.id + index} style={{ minWidth: '310px', padding: '10px 5px'}}>
                    <td className="order-table-order-card__name">{'--' + el.data.title}</td>               
                    <td style={{ paddingLeft: '5px', width: '25px', textAlign: 'start', color: '#000'}}>{el.count}</td>
                    {!isAggPrice 
                    ? 
                    <td style={{ paddingRight: '5px', width: '40px', textAlign: 'end', color: '#000'}}>{el.data.price}</td>
                    :
                    <td style={{ paddingRight: '5px', width: '40px', textAlign: 'end', color: '#000'}}>{el.data.oldPrice}</td>
                    }
                </tr>
            )}
             {/* проверка на el.data - костыль, тк в модуле принятия заказов она есть, а на сайте и в приложении - нет, нужно доработать модуль, потом вырезать*/}
            {good.wokData.souce && Object.values(good.wokData.souce).map((el) =>
                <tr key={el.id + index} style={{ minWidth: '310px', padding: '10px 5px'}}>
                    <td className="order-table-order-card__name">{'--' + (el.data ? el.data.title : el.title)}</td>               
                    <td style={{ paddingLeft: '5px', width: '25px', textAlign: 'start', color: '#000'}}>{el.count}</td>
                    {!isAggPrice 
                    ? 
                    <td style={{ paddingRight: '5px', width: '40px', textAlign: 'end', color: '#000'}}>{(el.data ? el.data.price : el.price)}</td>
                    :
                    <td style={{ paddingRight: '5px', width: '40px', textAlign: 'end', color: '#000'}}>{el.data.oldPrice}</td>
                    }
                </tr>
            )}
            </>
        );
    }

    renderGoods(good, index, isAggPrice){
        if (good.isWok) return this.renderWok(good, index, isAggPrice);

        return (
            <tr key={index} style={{ minWidth: '310px', padding: '10px 5px'}}>
                <td>
                    <div className="order-table-order-card__name">{good.data.title}</div>
                </td>
                <td style={{ paddingLeft: '5px', width: '25px', textAlign: 'start', color: '#000'}}>{good.count}</td>
                {!isAggPrice 
                ?
                <td style={{ paddingRight: '5px', width: '40px', textAlign: 'end', color: '#000'}}>{(good.category === this.props.settings.pizza && good.isMod) ? parseFloat(good.price) + parseFloat(this.props.pizza.find((el) => el.id === parseInt(good.id.split('_')[0])).modifications.find((el) => !el.default).price) : good.price }</td>
                :
                <td style={{ paddingRight: '5px', width: '40px', textAlign: 'end', color: '#000'}}>{(good.category === this.props.settings.pizza && good.isMod) ? parseFloat(good.oldPrice) + parseFloat(this.props.pizza.find((el) => el.id === parseInt(good.id.split('_')[0])).modifications.find((el) => !el.default).oldPrice) : good.oldPrice }</td>
                }                         
            </tr>
        );  
    }

    renderPayment(payment, ChangeCash) {
        if(payment === 'terminal')
            return <span>Терминал</span>

        if(payment === 'cash')
        {
            switch(ChangeCash){
                case('c1000'):{
                    return <span>Нал, сдача с 1000</span>
                }
                case('c1500'):{
                    return <span>Нал, сдача с 1500</span>
                }
                case('c2000'):{
                    return <span>Нал, сдача с 2000</span>
                }
                case('c5000'):{
                    return <span>Нал, сдача с 5000</span>
                }
                default:{
                    return <span>Нал б/с</span>
                }
            }
        }
    }

    renderSelectPoint(item) {
        if (!item.point && !item.zone) return '';
        if(item.zone && !this.props.filials.find((el) => el.id === item.zone.point)) return '';

        if(item.point)
        {
            return ({
                value: item.point.id,
                label: `${item.city ? item.city.name : '-'}, ${item.point.street}, ${item.point.house}`
            });
        }
        else
        {
            return ({
                value: `${this.props.filials.find((el) => el.id === item.zone.point).id}`,
                label: `${item.city ? item.city.name : '-'}, ${this.props.filials.find((el) => el.id === item.zone.point).street}, ${this.props.filials.find((el) => el.id === item.zone.point).house}`
            });
        }       
    }
    
    renderAdress(item) {
        if (!item.street && !item.point) 
        {
            if(!item.city)
                return '-';
            if(item.city)
                return `${item.city.name}`
        }

        if(item.deliveryMethod === "delivery")
            return `${item.city ? item.city.name : ''}${item.area ? ', ' + item.area : ''}${item.street ? ', ' + item.street : ''}${item.house ? ', ' + item.house : ''}${item.entrance ? `, подъезд ${item.entrance}` : ''}${item.floor ? `, этаж ${item.floor}` : ''}${item.room ? `, кв./оф. ${item.room}` : ''}`;
        
        if(item.deliveryMethod === "own")
            return `${item.city ? item.city.name : ''}${item.point.street ? ', ' + item.point.street: ''}${item.point.house ? ', ' + item.point.house : ''}`;
    }

    renderAdressSelect(item){
        return `${item.city ? item.city.name + ', ' : ''}${item.street}, ${item.house}`;
    }

renderStatus(item) {
    return <select onDoubleClick={((event) => {event.stopPropagation()})} onChange={((e) => {this.changeStatus(e, item)})} value={item.status} placeholder="Статус заказа" className="status-badge">
                <option value="new">Новый</option>
                <option value="processing">В обработке</option>
                <option value="not-confirmed">Не подтвержден</option>
                <option value="confirmed">Подтвержден</option>
                <option value="cook">Готовится</option>
                <option value="cooked">Приготовлен</option>
                <option value="delivery">В пути</option>
                <option value="done">Выполнен</option>
                <option value="written-off">Списан</option>
                <option value="cancel">Отменен</option>
                <option value="return">Возврат</option>
                <option value="deferred">Отложенный</option>
            </select> 
    }

renderBody() { 
        return (
            <tbody className="kt-datatable__body order_table">
                {
                    this.props.orders.length === 0 && (
                        <tr className="kt-datatable__row order_table">
                            <td className="kt-datatable__cell order_table">Заказов не найдено</td>
                        </tr>
                    )
                }
                {
                    this.props.orders.map((item, orderIndex) => {                        
                        return (
                            <tr onDoubleClick={this.handleDoubleClick(item)} key={item.id} className={`kt-datatable__row order_table ${item.status}`}>
                            <thead style={{ borderBottom: '1px solid #f2f3f8' }}>
                                <th className={`kt-indicator top ${item.status}`}></th>
                                <th 
                                className='order_table_head'
                                onDoubleClick={((event) => {
                                    event.stopPropagation()
                                })}                                
                                >
                                    <span style={{ fontWeight: '300' }}>{moment(item.created_at).format('HH:mm')}</span>
                                    <span style={{ fontWeight: '300' }}>{moment(item.created_at).format('DD.MM.YYYY')}</span>
                                    <span style={{ fontWeight: '300' }}>{'№ ' + item.id}</span>
                                    <button 
                                    className='order-table_arrow-show-details'
                                    onDoubleClick={((event) => {
                                        event.stopPropagation()
                                    })}   
                                    onClick={() => {
                                        this.toggleDetails(orderIndex)
                                    }}                   
                                    >                 
                                        <i className={`kt-menu__ver-arrow la la-angle-down${this.state.showDetails[orderIndex] ? ' Showed' : ''}`}></i>
                                    </button>
                                    <button className='button_order_table' onClick={this.handleClickCopy(moment(item.created_at).format('HH:mm') + ' ' + moment(item.created_at).format('DD.MM.YYYY') + '(№ ' + item.id + ')')}>
                                        <i className='kt-nav__link-icon flaticon2-copy order_table'/>
                                    </button>      

                                    {item.deliveryMethod === 'own' ? <span className='indicator_deliveryMethod'>Вынос</span> : <span className='indicator_deliveryMethod'>Доставка</span>}
                                    {item.isAggPrice && <span className='indicator_deliveryMethod'>ЯЕ</span>}                               
                                </th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th>{this.renderStatus(item)}</th>
                                <th>{this.props.loadingUpdateorder === item.id && <div className="kt-spinner kt-spinner--lg kt-spinner--info" style={{ transform: 'scale(0.6)'}}/>}</th>
                            </thead>

                                <td className={`kt-indicator bottom ${item.status}`}></td>
                                <td style={{ minWidth: '310px', padding: '10px 5px'}} className="kt-datatable__cell order_table">
                                    {!this.state.showDetails[orderIndex] && <button 
                                    className='order-table_button-show-details'
                                    onDoubleClick={((event) => {
                                        event.stopPropagation()
                                    })}   
                                    onClick={() => {
                                        this.toggleDetails(orderIndex)
                                    }}
                                    >
                                        Показать состав
                                    </button>}
                                
                                {
                                    this.state.showDetails[orderIndex] && 
                                    <table>
                                        {item.goods.map((good, index) => this.renderGoods(good, index, item.isAggPrice))}           
                                    </table>                                   
                                }
                                {
                                    this.state.showDetails[orderIndex] && item.unit &&
                                    <tr style={{ minWidth: '310px'}}>
                                        <td>
                                            <div className="order-table-order-card__name">Приборы</div>
                                        </td>
                                        <td style={{ paddingLeft: '5px', width: '25px', textAlign: 'start', color: '#000'}}>{item.unit}</td>
                                        <td style={{ paddingRight: '5px', width: '40px', textAlign: 'end', color: '#000'}}>0</td>                     
                                    </tr>
                                }
                            </td>

                            <td className="kt-datatable__cell order_table" style={{ width: '60px', padding: '10px 5px' }}>
                                <span style={{ color: '#000', display: 'flex', justifyContent: 'center' }}>{item.totalPrice}</span>
                            </td>

                            <td className="kt-datatable__cell order_table" style={{ width: '60px', padding: '10px 5px' }}>
                                <span style={{ color: '#000', display: 'flex', justifyContent: 'center' }}>{parseFloat(item.salePrice) + parseFloat(item.bonusCount)}</span>
                            </td>

                            <td className="kt-datatable__cell order_table" style={{ width: '160px', padding: '10px 5px' }}>
                                <span style={{ color: '#000', display: 'flex', justifyContent: 'center'  }}>
                                    {this.renderPayment(item.payment, item.changeCash)}
                                </span>
                            </td>

                            <td className="kt-datatable__cell order_table" style={{ width: '70px', padding: '10px 5px' }}>
                                {item.point && <span style={{ color: '#000', display: 'flex', justifyContent: 'center' }}>{`${item.point.minDeliveryTime}/${item.point.minSamTime}`}</span>}
                                {item.zone && <span style={{ color: '#000', display: 'flex', justifyContent: 'center'  }}>{`${this.props.filials.find((el) => el.id === item.zone.point).minDeliveryTime}/${this.props.filials.find((el) => el.id === item.zone.point).minSamTime}`}</span>}
                                {!item.point && !item.zone && <span style={{ display: 'flex', justifyContent: 'center' }}> </span>}
                            </td>


                            <td className="kt-datatable__cell order_table" style={{ width: '240px' }}>
                                <div style={{ width: '220px' }}>
                                    <Select
                                        isLoading={this.props.loadingFilials || this.props.loadingUpdateorder === item.id}
                                        isDisabled={this.props.loadingFilials || this.props.loadingUpdateorder === item.id}
                                        value={this.renderSelectPoint(item)}
                                        closeMenuOnSelect={true}
                                        onChange={(e) => {this.changePoint(e ? e.value : null, item)}}
                                        styles={{
                                            menuPortal: base => ({ ...base, zIndex: 9999 }) 
                                          }}
                                        menuPlacement="auto"
                                        options={[
                                            { value: null, label: "Не определен" },
                                            ...this.props.filials.map(el => ({
                                                value: el.id,
                                                label: this.renderAdressSelect(el)
                                            }))
                                        ]}                                     
                                        placeholder="Филиал"
                                        noOptionsMessage={() => 'Нет городов'}
                                    />                                    
                                </div>
                            </td>
                             
                            <td className="kt-datatable__cell order_table" style={{ width: '400px' }}>
                                <span style={{ width: '100%', display: 'flex', gap: '5px', alignItems: 'center', color: '#000'  }}>                                     
                                    {this.renderAdress(item)}
                                    {item.comment ? ', Комментарий: ' + item.comment : ''}
                                    <button className='button_order_table' onClick={this.handleClickCopy(String((item.city ? item.city.name : '') + (item.area ? ', ' + item.area : '') + (item.street ? ', ' + item.street : '') + (item.house ? ', ' + item.house : '')))}>
                                        <i className='flaticon2-copy order_table'/>
                                    </button>
                                </span>
                            </td>  

                            <td onDoubleClick={((event) => {event.stopPropagation()})} className="kt-datatable__cell order_table" style={{ width: '130px' }}>
                                <span style={{ display: 'flex', gap: '5px', alignItems: 'center', color: '#000', justifyContent: 'center' }}>
                                    {item.phone} 
                                    <button className='button_order_table' onClick={this.handleClickCopy(String(item.phone))}>
                                        <i className='kt-nav__link-icon flaticon2-copy order_table'/>
                                    </button>
                                </span>
                            </td>

                            {this.props.role === 4 &&
                            <td className="kt-datatable__cell order_table" style={{ width: '1.5rem'}}>
                                <button
                                className='button_order_table'
                                onClick={(() => {
                                    this.props.onDelete(item.id)
                                })}
                                >                                    
                                    <i className='kt-nav__link-icon flaticon2-trash order_table'></i>
                                </button>                           
                            </td>
                            }
                        </tr>
                        )
                    }) 
                }
            </tbody>
        );
    }

    render() {
        return (
            <table className="kt-datatable__table" style={{ minHeight: '500px', backgroundColor: '#00000000' }}>
                {this.renderBody()}
            </table>
        );
    }
}

const mapStateToProps = state => ({
    orders: state.orders.list,
    filters: state.orders.filters,
    loading: state.loaders.orders,
    filials: state.handbook.filials,
    role: state.user.info.role.id,
    settings: state.settings.init.categories,
    loadingUpdateorder: state.loaders.updateorder,  
});

const mapDispatchToProps = {
    updateOrderFromTable: (id, data, isArchive) => updateOrderFromTable(id, data, isArchive),
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OrdersTable));